// AWS Amplify Configuration Template - autogenerated at deploy time
/* eslint no-template-curly-in-string: 0*/
const config = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "app-be-platform-645193656683-uploads",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://kmff7t6wsg.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_i1AwPbHgs",
    APP_CLIENT_ID: "5pjcfjimcu0ohe73gtolkgg41c",
    IDENTITY_POOL_ID: "eu-central-1:6502a3e8-f572-4243-a090-0a59a2a7de80",
  },
  locales: {
    URL: "https://locales.platform.beta.beteiligungsboerse.net",
  },
  datastore: {
    URL: "https://datastore.platform.beta.beteiligungsboerse.net",
  },
  registration: {
    URL: "https://registration.platform.beta.beteiligungsboerse.net/v1",
    NEXT_PAGE: "https://app.platform.beta.beteiligungsboerse.net",
    TO_REFERRER: "https://app.platform.beta.beteiligungsboerse.net",
  },
  product: {
    PRODUCT_KEY: "platform",
  },
};

export default config;
